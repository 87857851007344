<template>
    <div>
        <div class="flex flex-h-center">
            <div id="my_camera" class="flex flex-v-center"></div>
        </div>
        <div class="flex flex-h-center margin-t" style="background-color: #eee;">
            <div class="flex1" id="waveform1" ref="waveform1"></div>
        </div>

        <div class="flex flex-between margin-ts relative" style="background-color: #eee;">
            <div class="flex1" id="waveform2" ref="waveform2"></div>
            <canvas :id="no" :style="{
                width: src ? 0 : '100%'
            }"
                style="position: absolute;right: 0;top:10px; width:300px;height: 90px; background-color: rgb(255, 204, 208)"></canvas>
        </div>

        <div class="flex flex-between margin-ts">
            <div>0:00</div>
            <div>{{ format_time(duration1) }}</div>
        </div>

        <div class="flex flex-between flex-v-center margin-t relative">

            <div class="flex flex-v-center">
                <div class="margin-r" v-if="no != 'example'">
                    <el-dropdown  :disabled="started" @command="updateVoice">
                        <span class="el-dropdown-link">
                            {{ $store.getters.getVoiceLabel(lang) }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu v-if="lang == 'zh'" slot="dropdown">
                            <el-dropdown-item command="Origin,原声">{{ $t('原声') }}</el-dropdown-item>
                            <el-dropdown-item command="Andy,中语男音">{{ $t('中语男音') }}</el-dropdown-item>
                            <el-dropdown-item command="Abby,中语女音">{{ $t('中语女音') }}</el-dropdown-item>
                            <el-dropdown-item command="Luca,粤语男音">{{ $t('粤语男音') }}</el-dropdown-item>
                            <el-dropdown-item command="Luna,粤语女音">{{ $t('粤语女音') }}</el-dropdown-item>
                            <el-dropdown-item command="Random,随机">{{ $t('随机') }}</el-dropdown-item>
                        </el-dropdown-menu>


                        <el-dropdown-menu v-else slot="dropdown">
                            <el-dropdown-item command="Origin,原声">{{ $t('原声') }}</el-dropdown-item>
                            <el-dropdown-item command="Andy,美式男音">{{ $t('美式男音') }}</el-dropdown-item>
                            <el-dropdown-item command="Abby,美式女音">{{ $t('美式女音') }}</el-dropdown-item>
                            <el-dropdown-item command="Luca,英式男音">{{ $t('英式男音') }}</el-dropdown-item>
                            <el-dropdown-item command="Luna,英式女音">{{ $t('英式女音') }}</el-dropdown-item>
                            <el-dropdown-item command="Random,随机">{{ $t('随机') }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <div>
                    <el-dropdown @command="updateRate">
                        <span class="el-dropdown-link">
                            {{ playbackRate }}x<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="0.6">0.6x</el-dropdown-item>
                            <el-dropdown-item command="0.8">0.8x</el-dropdown-item>
                            <el-dropdown-item command="1.0">1.0x</el-dropdown-item>
                            <el-dropdown-item command="1.2">1.2x</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="flex flex-v-center">

                <div class="st1 margin-x">Attempts: {{ times }}</div>

                <div v-if="started" class="flex flex-v-center">
                    <!-- <div class="btn disabled playback flex flex-v-center" v-if="isRecording">
                    <div class="prefix"></div>Continue playback
                </div> -->
                    <div class="btn" @click="finish()">Finish attempt</div>
                    <div class="recording" v-if="isRecording"> REC</div>
                    <div class="loading" v-else><i class="el-icon-loading"></i> PLAYING SOURCE FILE</div>
                </div>
                <div v-else class="flex flex-v-center">
                    <div @click="playPause()" :class="{ 'pause': isPlaying, 'play': !isPlaying }" class="icon "></div>
                    <div @click="stop()" class="icon stop  margin-x" :class="{ 'disabled': isStopped }"></div>
                    <div v-if="src" @click="seek(1)" :class="{ 'disabled': waveIndex == 1 }" class="icon small pre ">
                    </div>
                    <div v-if="src" @click="seek(2)" :class="{ 'disabled': waveIndex == 2 }"
                        class="icon small next margin-x">
                    </div>
                    <div @click="start()" class="start">Start</div>
                </div>

            </div>



            <el-tooltip effect="dark" content="Press the space bar to start and stop the recording" placement="top-end">
                <el-image src="/web/image/keyboard.png"></el-image>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Crunker from 'crunker';
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import Recorder from 'js-audio-recorder';
import Webcam from "webcamjs";
export default {
    name: "CclPlayer",

    data() {
        return {
            timer: 0,
            time: 0,
            isPlaying: false,
            isStopped: true,
            started: false,
            isRecording: false,
            voice1: "",
            duration1: 0,
            duration2: 0,
            src: null,
            src1: null,
            src2: null,
            wavesurfer1: null,
            wavesurfer2: null,
            recorder: null,
            drawRecordId: null,
            canvas: null,
            ctx: null,
            playbackRate: '1.0',
            times: 0,
            crunker: null,
            waveIndex: 0,
            loaded: false,
        };
    },

    computed: {
        voice() {
            return this.$store.getters.getVoice(this.lang);
        }
    },
    watch: {
        voice() {
            this.updateAudioList();
        },
        no(val) {
            console.log(val)
            if (val) {
                this.wavesurfer1.stop();
                this.wavesurfer2.stop();
                if (!this.lazyLoad) {
                    this.updateAudioList();
                } else {
                    this.loaded = false;
                }
            }
        }
    },

    props: {
        lazyLoad: {
            type: Boolean,
            default: false,
        },
        no: {
            type: String,
            default: "",
        },

        lang: {
            type: String,
            default: "en",
        },

        webcam: {
            type: Boolean,
            default: false,
        },

    },
    created() {
        this.crunker = new Crunker();

    },

    beforeDestroy() {
        this.closeWebcam();
    },

    mounted() {
        this.playbackRate = localStorage.getItem("ccl_playback_rate") || 1;
        this.$nextTick(() => {
            this.wavesurfer1 = WaveSurfer.create({
                container: this.$refs.waveform1,
                backgroundColor: '#eee',
                waveColor: '#aaa',
                height: 100,
                barWidth: 1,
                barHeight: 4,
                cursorColor: "#000",
                progressColor: "#aaa",
                backend: "MediaElement",
                audioRate: Number(this.playbackRate),
                interact: false,
                //使用时间轴插件
                plugins: [
                    // 插件--区域的配置
                    RegionsPlugin.create({
                        regionsMinLength: 2
                    }),
                ],
            });

            this.wavesurfer1.on("play", () => {
                this.isPlaying = true;
                this.isStopped = false;
            });

            this.wavesurfer1.on('pause', () => {
                this.isPlaying = false;
            });

            this.wavesurfer1.on('audioprocess', () => {
                if (this.src && this.wavesurfer1.getCurrentTime() >= this.duration1) {
                    this.waveIndex = 2;
                } else {
                    this.waveIndex = 1;
                }
            });



            this.wavesurfer1.on('finish', () => {
                this.isStopped = true;
                if (this.started) {
                    this.startRecorder();
                }
            });

            this.wavesurfer1.on('ready', () => {
                if (this.src) {
                    this.wavesurfer1.addRegion({
                        start: this.duration1,
                        end: this.duration2,
                        drag: false,
                        resize: false,
                        color: 'rgba(221,221,221,1)'
                    })
                } else {
                    this.duration1 = this.wavesurfer1.getDuration();
                }

            });



            this.wavesurfer2 = WaveSurfer.create({
                container: this.$refs.waveform2,
                backgroundColor: 'rgb(255, 204, 208)',
                waveColor: '#ff0000',
                height: 100,
                barWidth: 1,
                barHeight: 4,
                cursorColor: "#000",
                progressColor: "#aaa",
                backend: "MediaElement",
                audioRate: "1",
                interact: false,
                //使用时间轴插件
                plugins: [
                    // 插件--区域的配置
                    RegionsPlugin.create({
                        regionsMinLength: 0
                    }),
                ],
            });


            this.wavesurfer2.on('ready', () => {
                if (this.src) {
                    this.duration2 = this.wavesurfer2.getDuration();
                    this.wavesurfer2.addRegion({
                        start: 0,
                        end: this.duration1,
                        drag: false,
                        resize: false,
                        color: 'rgba(238,238,238,1)'
                    })
                }

            });

            if (this.no && !this.lazyLoad) {

                this.updateAudioList();
            }
        });


        this.recorder = new Recorder({
            sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
            sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
            numChannels: 1,                 // 声道，支持 1 或 2， 默认是1
            // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });

        // 特别提醒：此处需要使用require(相对路径)，否则会报错
    },
    methods: {
        updateRate(rate) {
            this.playbackRate = rate;
            localStorage.setItem("ccl_playback_rate", rate);
            this.wavesurfer1.setPlaybackRate(Number(this.playbackRate));
        },
        openWebcam() {
            if (this.webcam) {
                Webcam.set({
                    width: 160,//实时摄像机查看器的宽度
                    height: 120,//实时摄像机查看器的高度
                    // dest_width: 200,//捕获相机图像的宽
                    // dest_height: 200,//捕获相机图像的高
                    // crop_width: 200,//最终裁剪图像的宽度(以像素为单位)，默认为dest_width。
                    // crop_height: 200,//最终裁剪图像的高度(以像素为单位)，默认为dest_height。
                    image_format: "jpeg",//捕获图像的期望图像格式，可以是“jpeg”或“png”。
                    jpeg_quality: 90,//对于JPEG图像，这是理想的质量，从0(最差)到100(最好)。
                    enable_flash: true,//启用或禁用Flash回退，如果没有本机网络摄像头访问。
                    force_flash: false,//将此设置为true将始终在adobeflash回退模式下运行。
                    flip_horiz: false,//将此设置为true将水平翻转图像(镜像模式)。
                    fps: 30,//设置所需的fps(帧/秒)捕获速率。
                    flashNotDetectedText: "未检测到flash播放器的文本/html。",//未检测到flash播放器的文本/html。
                    unfreeze_snap: true,//是否在拍照后解冻相机
                });
                // DOM元素必须已经创建并且为空。将ID或CSS选择器传递给Webcam.attach()函数(挂载dom)
                Webcam.attach("#my_camera");
            }
        },

        closeWebcam() {
            if (this.webcam) {
                Webcam.reset();
            }
        },

        keyClick() {
            if (!this.started) {
                setTimeout(() => {
                    this.start();
                });
            }

            if (this.isRecording) {
                this.finish();
            }
        },

        seek(index) {
            this.waveIndex = index;
            if (index == 1) {
                this.wavesurfer1.seekTo(0);
                this.wavesurfer2.seekTo(0);
            } else {
                this.wavesurfer1.seekTo(this.duration1 / this.duration2 + 0.001);
                this.wavesurfer2.seekTo(this.duration1 / this.duration2 + 0.001);
            }

        },

        finish() {
            if (this.isPlaying) {
                this.$confirm('Are you sure you want to finish this attempt?<br/>You have not interpreted all segments yet.', '', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    this.started = false;
                    this.isRecording = false;
                    this.stop();
                    this.$bus.$emit("started", false);
                }).catch(() => {
                });
            } else {
                this.started = false;
                this.isRecording = false;
                this.recorder.stop();
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
                this.drawRecordId = null;
                this.src2 = URL.createObjectURL(this.recorder.getWAVBlob());
                this.$bus.$emit("content", this.recorder.getWAVBlob());
                this.crunker
                    .fetchAudio(this.src1, this.src2)
                    .then((buffers) => {
                        this.copyChannels(buffers);
                        return this.crunker.concatAudio(buffers);
                    })
                    .then((merged) => {
                        return this.crunker.export(merged, 'audio/mp3');
                    })
                    .then((output) => {
                        this.src = output.url;
                        this.wavesurfer2.load(this.src);
                        this.$nextTick(() => {
                            this.wavesurfer1.load(this.src);
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    });
                this.$bus.$emit("started", false);
            }
        },

        startRecorder() {
            this.recorder.start().then(() => {
                this.isRecording = true;
                this.canvas = document.getElementById(this.no);
                this.ctx = this.canvas.getContext("2d");
                this.drawRecord();
            })
        },

        drawRecord() {
            // 用requestAnimationFrame稳定60fps绘制
            this.drawRecordId = requestAnimationFrame(this.drawRecord);

            // 实时获取音频大小数据
            let dataArray = this.recorder.getRecordAnalyseData(),
                bufferLength = dataArray.length;

            // 填充背景色
            this.ctx.fillStyle = 'rgb(255, 204, 208)';
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

            // 设定波形绘制颜色
            this.ctx.lineWidth = 2;
            this.ctx.strokeStyle = '#ff0000';

            this.ctx.beginPath();

            var sliceWidth = this.canvas.width * 1.0 / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
                x = 0;          // 绘制点的x轴位置

            for (var i = 0; i < bufferLength; i++) {
                var v = dataArray[i] / 128.0;
                var y = v * this.canvas.height / 2;

                if (i === 0) {
                    // 第一个点
                    this.ctx.moveTo(x, y);
                } else {
                    // 剩余的点
                    this.ctx.lineTo(x, y);
                }
                // 依次平移，绘制所有点
                x += sliceWidth;
            }

            this.ctx.lineTo(this.canvas.width, this.canvas.height / 2);
            this.ctx.stroke();
        },




        format_time(time) {
            time = Math.ceil(time);
            if (time < 60) {
                return "00:" + (time > 9 ? time : ("0" + time));
            } else {
                let m = parseInt(time / 60);
                let s = time % 60;
                return (m > 9 ? m : '0' + m) + ":" + (s > 9 ? s : '0' + s)
            }
        },

        stop() {
            this.wavesurfer1.stop();
            this.isStopped = true;
            if (this.src) {
                this.wavesurfer2.stop();
            }
        },

        onLoadedmetadata() {
            if (this.duration > 0) return;
            this.$nextTick(() => {
                let voice = this.voice1 ? this.voice1 : this.$store.getters.getVoice(this.lang);
                if (this.$refs.audio.duration == window.Infinity && this.duration == 0) {
                    this.$http.post("/api/audio/duration/" + this.no + "/" + voice).then((duration) => {
                        if (duration == 0) {
                            setTimeout(() => {
                                this.onLoadedmetadata();
                            }, 1000);
                        } else {
                            this.duration = Math.ceil(duration);
                        }
                    })
                } else {
                    this.duration = Math.ceil(this.$refs.audio.duration);
                }
            })

        },


        updateVoice(voice) {
            localStorage.setItem(this.lang + "_audio_voice", voice);
            if (this.lang == "zh") {
                this.$store.commit("setZhVoice", voice);
            } else {
                this.$store.commit("setEnVoice", voice);
            }
            if (this.src) {
                this.src = null;
                this.wavesurfer1.load(this.src1);
                this.wavesurfer2.empty();
                this.wavesurfer2.clearRegions();
            }
            this.updateAudioList();
        },
        getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        load() {
            if (!this.loaded) {
                this.updateAudioList();
            }
        },

        stopPlay() {
            this.wavesurfer1.stop();
            this.wavesurfer2.stop();
        },

        updateAudioList() {

            if (this.no == "example") {
                this.src1 = "/web/audio/ccl_example.mp3";
                this.wavesurfer1.load(this.src1);
                this.loaded = true;
            } else {
                if (this.$store.getters.getVoice(this.lang) == "Random") {
                    this.voice1 = ["Andy", "Abby", "Luca", "Luna"][this.getRndInteger(0, 3)];
                } else {
                    this.voice1 = "";
                }
                this.$nextTick(() => {
                    let src = this.$host + "/api/audio/" + this.no + "/" + (this.voice1 ? this.voice1 : this.$store.getters.getVoice(this.lang)) + "?v=" + moment().format('MMDDHH');
                    let items = this.no.split("_");
                    if (items[2] == 0) {
                        items[2] = "briefing";
                        let briefing = this.$host + "/api/audio/" + items.join("_") + "/Origin?v=" + moment().format('MMDDHH');
                        this.crunker
                            .fetchAudio(briefing, src, '/web/audio/ccl.mp3')
                            .then((buffers) => {
                                this.copyChannels(buffers);
                                return this.crunker.concatAudio(buffers);
                            })
                            .then((merged) => {
                                return this.crunker.export(merged, 'audio/mp3');
                            })
                            .then((output) => {
                                this.src1 = output.url;
                                this.wavesurfer1.setPlaybackRate(Number(this.playbackRate));
                                this.wavesurfer1.load(this.src1);
                                this.loaded = true;
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    } else {
                        this.crunker
                            .fetchAudio(src, '/web/audio/ccl.mp3')
                            .then((buffers) => {
                                this.copyChannels(buffers);
                                return this.crunker.concatAudio(buffers);
                            })
                            .then((merged) => {
                                return this.crunker.export(merged, 'audio/mp3');
                            })
                            .then((output) => {
                                console.log(output)
                                this.src1 = output.url;
                                this.wavesurfer1.load(this.src1);
                                this.loaded = true;
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    }

                })
            }


        },

        copyChannels(buffers) {
            buffers.forEach((buffer, index) => {
                if (buffer.numberOfChannels == 1) {
                    let audioBuffer = new AudioBuffer({ length: buffers[index].length, numberOfChannels: 2, sampleRate: buffers[index].sampleRate });
                    const anotherArray = new Float32Array(buffers[index].length)
                    buffers[index].copyFromChannel(anotherArray, 0, 0)
                    audioBuffer.copyToChannel(anotherArray, 0, 0)
                    audioBuffer.copyToChannel(anotherArray, 1, 0)
                    buffers[index] = audioBuffer;
                }
            });
            return buffers;
        },
        start() {
            if (this.times > 0) {
                this.$confirm('Are you sure you want to repeat the segment?<br/>' + (this.times > 1 ? 'You will be penalized for doing so.' : 'One per dialogue without penalty.'), '', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    this.doStart()
                }).catch(() => {
                });
            } else {
                this.doStart()
            }
        },

        doStart() {
            this.times++;
            this.started = true;
            this.loading = true;
            this.isRecording = false;
            this.src = null;
            this.src2 = null;
            this.wavesurfer2.empty();
            this.wavesurfer2.clearRegions();
            this.wavesurfer1.load(this.src1);
            this.wavesurfer1.play(0)
            this.$bus.$emit("started", true);
        },

        playPause() {
            this.wavesurfer1.playPause();
            if (this.src) {
                this.wavesurfer2.playPause()
            }
        },
    },
};
</script>
<style scoped>
.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon.small {
    height: 30px;
    width: 30px;
}

.icon:hover {
    background-color: #000;
    border-color: #fff;
}

.icon.disabled {
    cursor: auto;
    border-color: #ccc;
}

.icon.disabled:hover {
    background-color: #fff;
}

.icon.disabled::after {
    background-color: #ccc;
}

.icon.pre::before {
    content: '';
    width: 8px;
    height: 8px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(-135deg);
    margin-left: 5px;
}

.icon.small.disabled::before,
.icon.small.disabled:hover::before {
    border-top-color: #ccc;
    border-right-color: #ccc;
}

.icon.small:hover::before {
    border-top-color: #fff;
    border-right-color: #fff;
}

.icon.next::before {
    content: '';
    width: 8px;
    height: 8px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(45deg);
    margin-right: 5px;
}

.icon.disabled:hover::after {
    background-color: #ccc;
}

.play::after {
    margin-left: 5px;
    content: '';
    display: inline-block;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid #000
}

.play:hover::after {
    border-left-color: #fff;
}



.pause::after {
    content: '';
    height: 10px;
    width: 6px;
    border-right: 3px solid #000;
    border-left: 3px solid #000;
}

.pause:hover::after {
    border-color: #fff;
}

.stop::after {
    content: '';
    height: 10px;
    width: 10px;
    background-color: #000;
}

.stop:hover::after {
    background-color: #fff;
}


.start {
    font-family: "Open Sans", sans-serif;
    background-color: #e6a13c;
    border-radius: 20px;
    font-size: 13px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    line-height: 38px;
    height: 40px;
    padding: 0 20px;
    width: 48px;
    text-align: center;
}

.start:hover {
    background-color: #ebc137;
}

.btn {
    border: 1px #000 solid;
    border-radius: 30px;
    padding: 0 25px;
    line-height: 45px;
    height: 45px;
    margin-left: 20px;
    cursor: pointer;
    font-weight: 600;
}

.btn:hover {
    background-color: #000;
    color: #fff;
}

.btn.disabled,
.btn.disabled:hover {
    color: #ccc;
    border-color: #ccc;
    background-color: #fff;
    cursor: auto;
}

.recording {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 20px;
    color: #f44f1d;
    border-radius: 5px;
    height: 30px;
    line-height: 28px;
    padding: 0 5px;
    font-size: 16px;
    font-weight: 400;
    border: 2px #f44f1d solid;
    -webkit-animation: twinkling 1s infinite ease-in-out;
}

.playback .prefix {
    display: flex;
    align-items: center;
}

.playback .prefix::before {
    display: inline-block;
    content: '';
    display: inline-block;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid #000
}

.playback.disabled .prefix::before {
    border-left-color: #ccc;
}

.playback .prefix::after {
    display: inline-block;
    content: '';
    display: inline-block;
    height: 15px;
    width: 3px;
    margin-left: 3px;
    background-color: #000;
    margin-right: 10px;
}

.playback.disabled .prefix::after {
    background-color: #ccc;
}

.recording::before {
    margin: 0 5px;
    display: inline-block;
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #f44f1d;
}

.loading {
    margin-left: 20px;
    color: #6674A0;
    border-radius: 5px;
    height: 30px;
    line-height: 28px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 400;
    border: 2px #6674A0 solid;
    -webkit-animation: twinkling 1s infinite ease-in-out;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes twinkling {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@keyframes twinkling {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}
</style>
